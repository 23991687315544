import * as React from "react"
import Layout from "../components/Layout"
import whitebg from '../images/backgrounds/whitebg.jpg'
import readmore from '../images/read-more-arrow.webp'
import thumbnail from '../images/blog-thumbnail.jpg'
import tree3 from '../images/tree-3.webp'


export default function BlogPage() {
    return (
        <Layout>

                <section className="h-fit w-screen bg-repeat px-28 pt-36 pb-16 text-black" style={{backgroundImage: `url(${whitebg})`}}>
                    <h1 className="text-7xl font-geometry uppercase">Spectrum Blog</h1>
                </section>


                <section className="h-fit w-screen flex flex-col gap-y-14 bg-repeat text-black py-12 px-28" style={{backgroundImage: `url(${whitebg})`}}>

                    <div className="h-fit flex flex-row justify-between">

                        <div className="w-1/2 flex flex-col">
                            <h2 className="text-5xl font-semibold">Lore ipsum dolor sit amet, consectetur</h2>
                            <p className="text-lg font-light mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        
                            <a className="flex flex-row items-center">
                                <text className="text-lg font-geometry font-bold uppercase mr-2">Read More</text>
                                <img className="h-1/5" src={readmore} />
                            </a>                        
                        </div>

                        <div className="w-2/5">
                            <img className="rounded-lg" src={thumbnail} />
                        </div>

                    </div>

                    <div className="h-fit flex flex-row justify-between">

                        <div className="w-1/2 flex flex-col">
                            <h2 className="text-5xl font-semibold">Lore ipsum dolor sit amet, consectetur</h2>
                            <p className="text-lg font-light mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        
                            <a className="flex flex-row items-center">
                                <text className="text-lg font-geometry font-bold uppercase mr-2">Read More</text>
                                <img className="h-1/5" src={readmore} />
                            </a>                        
                        </div>

                        <div className="w-2/5">
                            <img className="rounded-lg" src={thumbnail} />
                        </div>

                    </div>

                    <div className="h-fit flex flex-row justify-between">

                        <div className="w-1/2 flex flex-col">
                            <h2 className="text-5xl font-semibold">Lore ipsum dolor sit amet, consectetur</h2>
                            <p className="text-lg font-light mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        
                            <a className="flex flex-row items-center">
                                <text className="text-lg font-geometry font-bold uppercase mr-2">Read More</text>
                                <img className="h-1/5" src={readmore} />
                            </a>                        
                        </div>

                        <div className="w-2/5">
                            <img className="rounded-lg" src={thumbnail} />
                        </div>

                    </div>

                    <div className="h-fit flex flex-row justify-between">

                        <div className="w-1/2 flex flex-col">
                            <h2 className="text-5xl font-semibold">Lore ipsum dolor sit amet, consectetur</h2>
                            <p className="text-lg font-light mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        
                            <a className="flex flex-row items-center">
                                <text className="text-lg font-geometry font-bold uppercase mr-2">Read More</text>
                                <img className="h-1/5" src={readmore} />
                            </a>                        
                        </div>

                        <div className="w-2/5">
                            <img className="rounded-lg" src={thumbnail} />
                        </div>

                    </div>

                </section>


                <section className="h-fit w-screen flex flex-row items-center px-28 bg-purplish">

                    <div className="w-1/2 flex flex-col py-10">
                        <h1 className="text-7xl font-geometry">Join Us</h1>
                        <p className="text-xl drop-shadow-md font-light tracking-wide mt-5">
                            Spectrum consists of an interdisciplinary team of contributors to our open-access science mission repository.
                            We are looking for a variety of individuals passionate in the areas of systems engineering, science, and mechanical design.
                        </p>
                        <h2 className="text-5xl drop-shadow-md font-geometry text-white text-left tracking-widest">...</h2>
                    </div>

                    <div className="w-1/2">
                        <img src={tree3} />
                    </div>

                </section>


        </Layout>
    )
}
