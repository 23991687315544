import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import Footer from "./Footer"

export default function Layout({ title, children }) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <body className="bg-neutral-100 overflow-x-hidden max-w-full"/>
            </Helmet>
            <div className="scroll-smooth dark:text-white">
                <Navbar />
                {children}
                <Footer />
            </div>
        </>
    )
}